/* import __COLOCATED_TEMPLATE__ from './toggle-checkbox.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import inArray from 'fabscale-app/helpers/in-array';

let lastSelectionMap = new WeakMap();

interface Args {
  record: any;
  visibleData: any[];
  rowIndex: number;
  tableInstance: any;
  tableData: {
    selectedRecords: any[];
    toggleRecord: (
      record: any,
      isSelected: boolean,
      { inRangeRecords }: { inRangeRecords: any[] | null }
    ) => void;
    isLimitReached: boolean;
  };
}

export default class UiTableCellToggleCheckbox extends Component<Args> {
  @action
  onToggle(isSelected: boolean, { shiftKey }: { shiftKey: boolean }) {
    let { record, rowIndex, tableInstance } = this.args;
    if (isSelected && this.args.tableData.isLimitReached) {
      return;
    }

    // In addition to simply toggling the selected item, we can also toggle multiple rows when holding shift.
    // So: Select a checkbox, then select another while holding shift = select everything in between
    // NOTE: This does not handle pagination specially, for now
    // So if you select a row, then siwtch the page, and then shift-click somewhere,
    // It will select all rows based on the row index
    // Note that we do not check for record equality because it could change
    // You should NEVER _expect_ the in between records to be set - they are a completely optional progressive enhancement
    let previousRowIndex = shiftKey
      ? lastSelectionMap.get(tableInstance)
      : undefined;

    lastSelectionMap.set(tableInstance, rowIndex);

    let inRangeRecords =
      typeof previousRowIndex !== 'undefined'
        ? this._getInBetweenRecords(previousRowIndex, rowIndex)
        : null;

    this.args.tableData.toggleRecord(record, isSelected, {
      inRangeRecords,
    });
  }

  _getInBetweenRecords(from: number, to: number) {
    let { visibleData } = this.args;

    if (from > to) {
      let _to = to;
      to = from;
      from = _to;
    }

    let records = [];
    for (let i = from; i <= to; i++) {
      if (visibleData[i]) {
        records.push(visibleData[i]);
      }
    }

    return records;
  }

  get isDisabled() {
    return (
      this.args.tableData.isLimitReached &&
      !inArray(this.args.tableData.selectedRecords, this.args.record)
    );
  }

  get isInEditMode() {
    return this.args.record?.edit === true;
  }
}
