/* import __COLOCATED_TEMPLATE__ from './edit-parameters.hbs'; */
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import ErrorParserService from 'fabscale-app/services/error-parser';
import SettingsService from 'fabscale-app/services/settings';
import LabSampleStore from 'fabscale-app/services/store/lab-sample';
import { LabSample } from 'fabscale-app/models/lab-sample';

interface Args {
  record: any;
  tableData: any;
  closeEditMode: () => void;
  onEditRowSuccess: (labSample: LabSample) => void;
}

export default class PageRoastPicTableEditParameters extends Component<Args> {
  @service settings: SettingsService;
  @service('store/lab-sample') labSamplesStore: LabSampleStore;
  @service('error-parser') errorParser: ErrorParserService;
  @service l10n: L10nService;

  @tracked error?: string;

  @tracked _lotNumber: undefined | string = undefined;
  @tracked _roastBatch: undefined | any = undefined;

  get unitSystem() {
    return this.settings.locationSettings.unitSystem;
  }

  get dateRange() {
    return this.args.tableData.filters.dateRange;
  }

  get lotNumber() {
    return this._lotNumber || this.args.record.labSample.lotNumber;
  }

  get roastBatch() {
    return this._roastBatch || this.args.record.labSample.roastBatch;
  }

  @action
  onLotNumberCreate(lotNumber: string) {
    this.onLotNumberChange(lotNumber);
  }

  @action
  onLotNumberChange(lotNumber: string) {
    this._lotNumber = lotNumber;
  }

  @action
  onRoastBatchTimestampCreate(roastBatch: any) {
    this.onRoastBatchTimestampChange(roastBatch);
  }

  @action
  onRoastBatchTimestampChange(roastBatch: any) {
    this._roastBatch = roastBatch;
  }

  submitFormTask = dropTask(async () => {
    const input = {
      lotNumber: this._lotNumber,
      roastBatchId: this._roastBatch?.id,
    };

    await this.labSamplesStore
      .updateLabSample(this.args.record.labSample.id, input)
      .then((labSample) => {
        this.args.onEditRowSuccess(labSample);
        this.args.closeEditMode();
      });
  });
}
