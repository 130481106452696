/* import __COLOCATED_TEMPLATE__ from './defects.hbs'; */
import Component from '@glimmer/component';
import { LabSample } from 'fabscale-app/models/lab-sample';
import { DateTime } from 'luxon';
import { deserializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import { Chart, ChartItem } from 'chart.js/auto';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import PdfService from 'fabscale-app/services/pdf';
import { tracked } from '@glimmer/tracking';
import ScreenService from 'fabscale-app/services/screen';

interface Args {
  firstLabSample: LabSample;
  secondLabSample: LabSample;
  labSampleUpdate: (labSample: LabSample) => void;
}

export default class RoastPicCompareDefects extends Component<Args> {
  @service pdf: PdfService;
  @service screen: ScreenService;

  @tracked currentOffset = 0;

  @tracked displayedDefectsValue = '';
  @tracked displayedNoDefectsValue = '';

  defectsChart: Chart | null = null;

  get isMobile() {
    return this.screen.isMobile;
  }

  get formattedFirstCreatedDate() {
    const date = deserializeDate(this.args.firstLabSample.createdDate as any);
    return `Recorded at ${date.toLocaleString(DateTime.DATETIME_SHORT)}`;
  }

  get formattedSecondCreatedDate() {
    const date = deserializeDate(this.args.secondLabSample.createdDate as any);
    return `Recorded at ${date.toLocaleString(DateTime.DATETIME_SHORT)}`;
  }

  printPageTask = dropTask(async () => {
    let fileName = `fabscale-roastpic-defects-${DateTime.local().toISODate()}.pdf`;
    await this.pdf.generateForCurrentPageTask.perform(fileName);
  });

  @action
  moveTo(offset: number) {
    this.currentOffset = offset;
  }

  @action
  drawDefectsChart(element: HTMLCanvasElement) {
    const ctx = element.getContext('2d') as ChartItem;

    const labels = Array.from(
      new Set([
        ...(this.args.firstLabSample.defectsHist?.map((bin) => bin.count) ||
          []),
        ...(this.args.secondLabSample.defectsHist?.map((bin) => bin.count) ||
          []),
      ])
    );

    const datasets = [
      {
        label: `#${this.args.firstLabSample.id}`,
        data: this.args.firstLabSample.defectsHist,
        backgroundColor: '#0F7799',
      },
      {
        label: `#${this.args.secondLabSample.id}`,
        data: this.args.secondLabSample.defectsHist,
        backgroundColor: '#A0D2F7',
      },
    ];

    const chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        parsing: {
          xAxisKey: 'count',
          yAxisKey: 'value',
        },
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            onClick: (e, legendItem, legend) => {
              Chart.defaults.plugins.legend.onClick.call(
                legend,
                e,
                legendItem,
                legend
              );

              this.updateDisplayedDefectsValues(chart as any);
            },
          },
        },
      },
    });

    this.defectsChart = chart as any;

    this.updateDisplayedDefectsValues(chart as any);
  }

  updateDisplayedDefectsValues(chart: Chart) {
    const visibleDatasets = chart.data.datasets.filter((dataset, index) => {
      const meta = chart.getDatasetMeta(index);
      return meta && !meta.hidden;
    });

    let defectsValues: string[] = [];
    let noDefectsValues: string[] = [];

    visibleDatasets.forEach((dataset) => {
      let sample;
      if (dataset.label === `#${this.args.firstLabSample.id}`) {
        sample = this.args.firstLabSample;
      } else if (dataset.label === `#${this.args.secondLabSample.id}`) {
        sample = this.args.secondLabSample;
      }

      if (sample) {
        const numberBeans = sample.numberOfBeans || 0;
        const defectsCatOne = sample.numberDefectsCatOne || 0;

        const defectsPercentage = ((defectsCatOne / numberBeans) * 100).toFixed(
          2
        );
        const noDefectsPercentage = (
          ((numberBeans - defectsCatOne) / numberBeans) *
          100
        ).toFixed(2);

        defectsValues.push(
          `${defectsPercentage}% (${defectsCatOne} of ${numberBeans})`
        );
        noDefectsValues.push(`${noDefectsPercentage}%`);
      }
    });

    this.displayedDefectsValue = defectsValues.join(' / ') || '-';
    this.displayedNoDefectsValue = noDefectsValues.join(' / ') || '-';
  }

  @action
  onLabSampleUpdate(labSample: LabSample) {
    this.args.labSampleUpdate(labSample);
  }

  defectsPercentage(labSample: LabSample) {
    const numberBeans = labSample.numberOfBeans;

    const defectsCatOne = labSample.numberDefectsCatOne;

    return ((defectsCatOne! / numberBeans!) * 100).toFixed(2);
  }

  noDefectsPercentage(labSample: LabSample) {
    const defectsCatOne = labSample.numberDefectsCatOne;
    const numberBeans = labSample.numberOfBeans;

    return (((numberBeans! - defectsCatOne!) / numberBeans!) * 100).toFixed(2);
  }
}
