/* import __COLOCATED_TEMPLATE__ from './color.hbs'; */
import Component from '@glimmer/component';
import { LabSample } from 'fabscale-app/models/lab-sample';
import { DateTime } from 'luxon';
import { deserializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import { Chart, ChartItem } from 'chart.js/auto';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { service } from '@ember/service';
import PdfService from 'fabscale-app/services/pdf';
import { tracked } from '@glimmer/tracking';
import ScreenService from 'fabscale-app/services/screen';

interface Args {
  firstLabSample: LabSample;
  secondLabSample: LabSample;
  labSampleUpdate: (labSample: LabSample) => void;
}

export default class RoastPicCompareColor extends Component<Args> {
  @service pdf: PdfService;
  @service screen: ScreenService;
  @tracked currentOffset = 0;

  @tracked displayedRoastScoreValue = '';
  @tracked displayedLStarValue = '';
  @tracked displayedAStarValue = '';
  @tracked displayedBStarValue = '';

  roastScoreChart: Chart | null = null;
  lStarChart: Chart | null = null;
  aStarChart: Chart | null = null;
  bStarChart: Chart | null = null;

  get isMobile() {
    return this.screen.isMobile;
  }

  formatCreatedDate(sample: LabSample) {
    const date = deserializeDate(sample.createdDate as any);
    return `Recorded at ${date.toLocaleString(DateTime.DATETIME_SHORT)}`;
  }

  get formattedFirstCreatedDate() {
    return this.formatCreatedDate(this.args.firstLabSample);
  }

  get formattedSecondCreatedDate() {
    return this.formatCreatedDate(this.args.secondLabSample);
  }

  printPageTask = dropTask(async () => {
    let fileName = `fabscale-roastpic-color-${DateTime.local().toISODate()}.pdf`;

    await this.pdf.generateForCurrentPageTask.perform(fileName);
  });

  @action
  moveTo(offset: number) {
    this.currentOffset = offset;
  }

  @action
  onLabSampleUpdate(labSample: LabSample) {
    this.args.labSampleUpdate(labSample);
  }

  @action
  drawChart(
    element: HTMLCanvasElement,
    firstSampleData: any[] | undefined,
    secondSampleData: any[] | undefined,
    dataKey: string,
    valueKey: string,
    deviationKey?: string
  ) {
    const ctx = element.getContext('2d') as ChartItem;
    let labels: any[];
    let datasets: any[];

    labels = Array.from(
      new Set([
        ...(firstSampleData?.map((bin) => bin.count) || []),
        ...(secondSampleData?.map((bin) => bin.count) || []),
      ])
    );

    datasets = [
      {
        label: `#${this.args.firstLabSample.id}`,
        data: firstSampleData,
        backgroundColor: '#0F7799',
      },
      {
        label: `#${this.args.secondLabSample.id}`,
        data: secondSampleData,
        backgroundColor: '#A0D2F7',
      },
    ];

    const chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        parsing: {
          xAxisKey: 'count',
          yAxisKey: 'value',
        },
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            onClick: (e, legendItem, legend) => {
              Chart.defaults.plugins.legend.onClick.call(
                legend,
                e,
                legendItem,
                legend
              );
              this.updateDisplayedValue(chart, valueKey, deviationKey);
            },
          },
        },
      },
    });

    this.storeChartInstance(dataKey, chart);

    this.updateDisplayedValue(chart, valueKey, deviationKey);
  }

  updateDisplayedValue(chart: Chart, valueKey: string, deviationKey?: string) {
    const visibleDatasets = chart.data.datasets.filter((dataset, index) => {
      const meta = chart.getDatasetMeta(index);
      return meta && !meta.hidden;
    });

    let displayedValue = '';

    visibleDatasets.forEach((dataset, idx) => {
      let sample: any = '';
      if (dataset.label === `#${this.args.firstLabSample.id}`) {
        sample = this.args.firstLabSample;
      } else if (dataset.label === `#${this.args.secondLabSample.id}`) {
        sample = this.args.secondLabSample;
      }

      if (sample) {
        const value = sample[valueKey];
        const deviation = deviationKey ? sample[deviationKey] : null;

        displayedValue += `${value}`;
        if (deviation !== null && deviation !== undefined) {
          displayedValue += ` ± ${deviation}`;
        }

        if (idx < visibleDatasets.length - 1) {
          displayedValue += ' / ';
        }
      }
    });

    this.setDisplayedValue(valueKey, displayedValue || '-');
  }

  storeChartInstance(dataKey: string, chart: Chart) {
    if (dataKey === 'gourmetColorHist') {
      this.roastScoreChart = chart;
    } else if (dataKey === 'lstarHist') {
      this.lStarChart = chart;
    } else if (dataKey === 'astarHist') {
      this.aStarChart = chart;
    } else if (dataKey === 'bstarHist') {
      this.bStarChart = chart;
    }
  }

  setDisplayedValue(valueKey: string, value: string) {
    if (valueKey === 'gourmetColorAvg') {
      this.displayedRoastScoreValue = value;
    } else if (valueKey === 'lstarAvg') {
      this.displayedLStarValue = value;
    } else if (valueKey === 'astarAvg') {
      this.displayedAStarValue = value;
    } else if (valueKey === 'bstarAvg') {
      this.displayedBStarValue = value;
    }
  }

  @action
  drawRoastScoreChart(element: HTMLCanvasElement) {
    this.drawChart(
      element,
      this.args.firstLabSample.gourmetColorHist,
      this.args.secondLabSample.gourmetColorHist,
      'gourmetColorHist',
      'gourmetColorAvg',
      'gourmetColorDeviation'
    );
  }

  @action
  drawLStarChart(element: HTMLCanvasElement) {
    this.drawChart(
      element,
      this.args.firstLabSample.lstarHist,
      this.args.secondLabSample.lstarHist,
      'lstarHist',
      'lstarAvg',
      'lstarDeviation'
    );
  }

  @action
  drawAStarChart(element: HTMLCanvasElement) {
    this.drawChart(
      element,
      this.args.firstLabSample.astarHist,
      this.args.secondLabSample.astarHist,
      'astarHist',
      'astarAvg',
      'astarDeviation'
    );
  }

  @action
  drawBStarChart(element: HTMLCanvasElement) {
    this.drawChart(
      element,
      this.args.firstLabSample.bstarHist,
      this.args.secondLabSample.bstarHist,
      'bstarHist',
      'bstarAvg',
      'bstarDeviation'
    );
  }
}
