import { EnumsBase } from './-base';
import {
  UnitType,
  UnitsDensity,
  UnitsEnergy,
  UnitsOrdinal,
  UnitsPressure,
  UnitsRatio,
  UnitsRoastColor,
  UnitsTemperature,
  UnitsTime,
  UnitsVolume,
  UnitsWeight,
} from './units';

export const NumericRoastBatchParameterTypes = [
  'ROAST_DURATION',
  'CYCLE_DURATION',
  'COOLING_DURATION',
  'START_TO_FIRST_CRACK_DURATION',
  'START_TO_COLOR_CHANCE_DURATION',
  'END_TEMPERATURE_ROAST',
  'QUENCHING_DURATION',
  'GAS_CONSUMPTION_MAIN_BURNER',
  'GAS_CONSUMPTION_AFTER_BURNER',
  'AIR_CONSUMPTION',
  'DISCHARGING_DURATION_COOLER',
  'END_TEMPERATURE_COOLER',
  'POWER_CONSUMPTION',
  'WATER_CONSUMPTION_PRECOOLING',
  'DISCHARGING_DURATION_ROASTER',
  'FEEDING_TEMPERATURE_ROASTER',
  'END_RELATIVE_UNDERPRESSURE_ROASTER',
  'FEEDING_SUPPLY_AIR_TEMPERATURE_ROASTER',
  'END_SUPPLY_AIR_TEMPERATURE_ROAST',
  'PRODUCT_TEMPERATURE_PREHEATER_DISCHARGE',
  'INPUT_WEIGHT',
  'NUMBER_OF_REDUCTION_STEPS',
  'ROAST_COLOR',
  'ROAST_MOISTURE',
  'ROAST_DENSITY',
  'SENSORIAL_SCORE',
  'OUTPUT_WEIGHT',
  'WEIGHT_LOSS_ACTUAL',
  'TURNING_POINT_TIME',
  'TURNING_POINT_TEMPERATURE',
  'RECIPE_ROAST_DURATION',
  'RECIPE_COOLING_DURATION',
  'RECIPE_FEEDING_TEMPERATURE_ROASTER',
  'RECIPE_END_TEMPERATURE_ROAST',
  'RECIPE_FEEDING_SUPPLY_AIR_TEMPERATURE_ROASTER',
  'RECIPE_END_SUPPLY_AIR_TEMPERATURE_ROAST',
  'RECIPE_INPUT_WEIGHT',
  'MAIN_BURNER_GAS_CONSUMPTION_HEATING_UP',
  'MAIN_BURNER_GAS_CONSUMPTION_STANDBY',
  'MAIN_BURNER_GAS_CONSUMPTION_ROASTING',
  'MAIN_BURNER_GAS_CONSUMPTION_TOTAL',
  'MAIN_BURNER_OIL_CONSUMPTION_HEATING_UP',
  'MAIN_BURNER_OIL_CONSUMPTION_STANDBY',
  'MAIN_BURNER_OIL_CONSUMPTION_ROASTING',
  'MAIN_BURNER_OIL_CONSUMPTION_TOTAL',
  'MAIN_BURNER_HYDROGEN_CONSUMPTION_HEATING_UP',
  'MAIN_BURNER_HYDROGEN_CONSUMPTION_STANDBY',
  'MAIN_BURNER_HYDROGEN_CONSUMPTION_ROASTING',
  'MAIN_BURNER_HYDROGEN_CONSUMPTION_TOTAL',
  'AFTER_BURNER_GAS_CONSUMPTION_HEATING_UP',
  'AFTER_BURNER_GAS_CONSUMPTION_STANDBY',
  'AFTER_BURNER_GAS_CONSUMPTION_ROASTING',
  'AFTER_BURNER_GAS_CONSUMPTION_TOTAL',
  'AFTER_BURNER_OIL_CONSUMPTION_HEATING_UP',
  'AFTER_BURNER_OIL_CONSUMPTION_STANDBY',
  'AFTER_BURNER_OIL_CONSUMPTION_ROASTING',
  'AFTER_BURNER_OIL_CONSUMPTION_TOTAL',
  'AFTER_BURNER_HYDROGEN_CONSUMPTION_HEATING_UP',
  'AFTER_BURNER_HYDROGEN_CONSUMPTION_STANDBY',
  'AFTER_BURNER_HYDROGEN_CONSUMPTION_ROASTING',
  'AFTER_BURNER_HYDROGEN_CONSUMPTION_TOTAL',
  'POWER_CONSUMPTION_HEATING_UP',
  'POWER_CONSUMPTION_STANDBY',
  'POWER_CONSUMPTION_ROASTING',
  'POWER_CONSUMPTION_TOTAL',
  'AIR_CONSUMPTION_HEATING_UP',
  'AIR_CONSUMPTION_STANDBY',
  'AIR_CONSUMPTION_ROASTING',
  'AIR_CONSUMPTION_TOTAL',
  'PROFORTE_GAS_CONSUMPTION_HEATING_UP',
  'PROFORTE_GAS_CONSUMPTION_STANDBY',
  'PROFORTE_GAS_CONSUMPTION_ROASTING',
  'PROFORTE_GAS_CONSUMPTION_TOTAL',
  'PROFORTE_POWER_CONSUMPTION_HEATING_UP',
  'PROFORTE_POWER_CONSUMPTION_STANDBY',
  'PROFORTE_POWER_CONSUMPTION_ROASTING',
  'PROFORTE_POWER_CONSUMPTION_TOTAL',
  'PROFORTE_AIR_CONSUMPTION_HEATING_UP',
  'PROFORTE_AIR_CONSUMPTION_STANDBY',
  'PROFORTE_AIR_CONSUMPTION_ROASTING',
  'PROFORTE_AIR_CONSUMPTION_TOTAL',
  'ROAST_COLOR_2',
] as const;

export const BooleanRoastBatchParameterTypes = [
  // Bad batch indicators
  'BURNER_OFF_DURING_ROAST',
  'PROCESS_WATER_ACTIVATED_AT_BURNER_OFF',
  'EMERGENCY_WATER',
  'END_ROASTER_MANUAL',
  'EMERGENCY_WATER_COOLER',
  'BAD_BATCH_OUTLET_SELECTED',
  'DESTONER_BYPASS',
  // Others
  'IS_FIRST_BATCH',
  'IS_LAST_BATCH',
] as const;

export const NumericCurveRoastBatchParameterTypes = [
  'PRODUCT_TEMPERATURE',
  'SUPPLY_AIR_TEMPERATURE',
] as const;

export type BooleanRoastBatchParameterType =
  typeof BooleanRoastBatchParameterTypes[number];
export type NumericRoastBatchParameterType =
  typeof NumericRoastBatchParameterTypes[number];
export type NumericCurveRoastBatchParameterType =
  typeof NumericCurveRoastBatchParameterTypes[number];

export type RoastBatchParameterType =
  | NumericRoastBatchParameterType
  | BooleanRoastBatchParameterType
  | NumericCurveRoastBatchParameterType;

const NumericParameterUnitTypeMap: {
  [key in NumericRoastBatchParameterType]: UnitType;
} = {
  INPUT_WEIGHT: UnitsWeight,
  OUTPUT_WEIGHT: UnitsWeight,
  RECIPE_INPUT_WEIGHT: UnitsWeight,
  WEIGHT_LOSS_ACTUAL: UnitsRatio,
  ROAST_DURATION: UnitsTime,
  CYCLE_DURATION: UnitsTime,
  COOLING_DURATION: UnitsTime,
  QUENCHING_DURATION: UnitsTime,
  DISCHARGING_DURATION_ROASTER: UnitsTime,
  DISCHARGING_DURATION_COOLER: UnitsTime,
  RECIPE_ROAST_DURATION: UnitsTime,
  RECIPE_COOLING_DURATION: UnitsTime,
  GAS_CONSUMPTION_MAIN_BURNER: UnitsVolume,
  GAS_CONSUMPTION_AFTER_BURNER: UnitsVolume,
  AIR_CONSUMPTION: UnitsVolume,
  POWER_CONSUMPTION: UnitsEnergy,
  WATER_CONSUMPTION_PRECOOLING: UnitsVolume,
  START_TO_FIRST_CRACK_DURATION: UnitsTime,
  START_TO_COLOR_CHANCE_DURATION: UnitsTime,
  ROAST_COLOR: UnitsRoastColor,
  ROAST_COLOR_2: UnitsRoastColor,
  ROAST_MOISTURE: UnitsRatio,
  ROAST_DENSITY: UnitsDensity,
  SENSORIAL_SCORE: UnitsOrdinal,
  NUMBER_OF_REDUCTION_STEPS: UnitsOrdinal,
  FEEDING_TEMPERATURE_ROASTER: UnitsTemperature,
  END_TEMPERATURE_ROAST: UnitsTemperature,
  FEEDING_SUPPLY_AIR_TEMPERATURE_ROASTER: UnitsTemperature,
  END_SUPPLY_AIR_TEMPERATURE_ROAST: UnitsTemperature,
  END_TEMPERATURE_COOLER: UnitsTemperature,
  PRODUCT_TEMPERATURE_PREHEATER_DISCHARGE: UnitsTemperature,
  END_RELATIVE_UNDERPRESSURE_ROASTER: UnitsPressure,
  TURNING_POINT_TIME: UnitsTime,
  TURNING_POINT_TEMPERATURE: UnitsTemperature,
  RECIPE_FEEDING_TEMPERATURE_ROASTER: UnitsTemperature,
  RECIPE_END_TEMPERATURE_ROAST: UnitsTemperature,
  RECIPE_FEEDING_SUPPLY_AIR_TEMPERATURE_ROASTER: UnitsTemperature,
  RECIPE_END_SUPPLY_AIR_TEMPERATURE_ROAST: UnitsTemperature,
  MAIN_BURNER_GAS_CONSUMPTION_HEATING_UP: UnitsVolume,
  MAIN_BURNER_GAS_CONSUMPTION_STANDBY: UnitsVolume,
  MAIN_BURNER_GAS_CONSUMPTION_ROASTING: UnitsVolume,
  MAIN_BURNER_GAS_CONSUMPTION_TOTAL: UnitsVolume,
  MAIN_BURNER_OIL_CONSUMPTION_HEATING_UP: UnitsVolume,
  MAIN_BURNER_OIL_CONSUMPTION_STANDBY: UnitsVolume,
  MAIN_BURNER_OIL_CONSUMPTION_ROASTING: UnitsVolume,
  MAIN_BURNER_OIL_CONSUMPTION_TOTAL: UnitsVolume,
  MAIN_BURNER_HYDROGEN_CONSUMPTION_HEATING_UP: UnitsVolume,
  MAIN_BURNER_HYDROGEN_CONSUMPTION_STANDBY: UnitsVolume,
  MAIN_BURNER_HYDROGEN_CONSUMPTION_ROASTING: UnitsVolume,
  MAIN_BURNER_HYDROGEN_CONSUMPTION_TOTAL: UnitsVolume,
  AFTER_BURNER_GAS_CONSUMPTION_HEATING_UP: UnitsVolume,
  AFTER_BURNER_GAS_CONSUMPTION_STANDBY: UnitsVolume,
  AFTER_BURNER_GAS_CONSUMPTION_ROASTING: UnitsVolume,
  AFTER_BURNER_GAS_CONSUMPTION_TOTAL: UnitsVolume,
  AFTER_BURNER_OIL_CONSUMPTION_HEATING_UP: UnitsVolume,
  AFTER_BURNER_OIL_CONSUMPTION_STANDBY: UnitsVolume,
  AFTER_BURNER_OIL_CONSUMPTION_ROASTING: UnitsVolume,
  AFTER_BURNER_OIL_CONSUMPTION_TOTAL: UnitsVolume,
  AFTER_BURNER_HYDROGEN_CONSUMPTION_HEATING_UP: UnitsVolume,
  AFTER_BURNER_HYDROGEN_CONSUMPTION_STANDBY: UnitsVolume,
  AFTER_BURNER_HYDROGEN_CONSUMPTION_ROASTING: UnitsVolume,
  AFTER_BURNER_HYDROGEN_CONSUMPTION_TOTAL: UnitsVolume,
  POWER_CONSUMPTION_HEATING_UP: UnitsEnergy,
  POWER_CONSUMPTION_STANDBY: UnitsEnergy,
  POWER_CONSUMPTION_ROASTING: UnitsEnergy,
  POWER_CONSUMPTION_TOTAL: UnitsEnergy,
  AIR_CONSUMPTION_HEATING_UP: UnitsVolume,
  AIR_CONSUMPTION_STANDBY: UnitsVolume,
  AIR_CONSUMPTION_ROASTING: UnitsVolume,
  AIR_CONSUMPTION_TOTAL: UnitsVolume,
  PROFORTE_GAS_CONSUMPTION_HEATING_UP: UnitsVolume,
  PROFORTE_GAS_CONSUMPTION_STANDBY: UnitsVolume,
  PROFORTE_GAS_CONSUMPTION_ROASTING: UnitsVolume,
  PROFORTE_GAS_CONSUMPTION_TOTAL: UnitsVolume,
  PROFORTE_POWER_CONSUMPTION_HEATING_UP: UnitsEnergy,
  PROFORTE_POWER_CONSUMPTION_STANDBY: UnitsEnergy,
  PROFORTE_POWER_CONSUMPTION_ROASTING: UnitsEnergy,
  PROFORTE_POWER_CONSUMPTION_TOTAL: UnitsEnergy,
  PROFORTE_AIR_CONSUMPTION_HEATING_UP: UnitsVolume,
  PROFORTE_AIR_CONSUMPTION_STANDBY: UnitsVolume,
  PROFORTE_AIR_CONSUMPTION_ROASTING: UnitsVolume,
  PROFORTE_AIR_CONSUMPTION_TOTAL: UnitsVolume,
} as const;

export function getUnitTypeForNumericParameterType(
  parameterType: NumericRoastBatchParameterType
): UnitType {
  return NumericParameterUnitTypeMap[parameterType];
}

export class RoastBatchParameterTypesEnums extends EnumsBase<RoastBatchParameterType> {
  constructor(...rest: any) {
    super(...rest);

    let { l10n } = this;

    let labels: { [key in RoastBatchParameterType]: string } = {
      MAIN_BURNER_GAS_CONSUMPTION_HEATING_UP: l10n.t(
        'Main burner gas consumption heating up'
      ),
      MAIN_BURNER_GAS_CONSUMPTION_STANDBY: l10n.t(
        'Main burner gas consumption standby'
      ),
      MAIN_BURNER_GAS_CONSUMPTION_ROASTING: l10n.t(
        'Main burner gas consumption roasting'
      ),
      MAIN_BURNER_GAS_CONSUMPTION_TOTAL: l10n.t(
        'Main burner gas consumption total'
      ),
      MAIN_BURNER_OIL_CONSUMPTION_HEATING_UP: l10n.t(
        'Main burner oil consumption heating up'
      ),
      MAIN_BURNER_OIL_CONSUMPTION_STANDBY: l10n.t(
        'Main burner oil consumption standby'
      ),
      MAIN_BURNER_OIL_CONSUMPTION_ROASTING: l10n.t(
        'Main burner oil consumption roasting'
      ),
      MAIN_BURNER_OIL_CONSUMPTION_TOTAL: l10n.t(
        'Main burner oil consumption total'
      ),
      MAIN_BURNER_HYDROGEN_CONSUMPTION_HEATING_UP: l10n.t(
        'Main burner hydrogen consumption heating up'
      ),
      MAIN_BURNER_HYDROGEN_CONSUMPTION_STANDBY: l10n.t(
        'Main burner hydrogen consumption standby'
      ),
      MAIN_BURNER_HYDROGEN_CONSUMPTION_ROASTING: l10n.t(
        'Main burner hydrogen consumption roasting'
      ),
      MAIN_BURNER_HYDROGEN_CONSUMPTION_TOTAL: l10n.t(
        'Main burner hydrogen consumption total'
      ),
      AFTER_BURNER_GAS_CONSUMPTION_HEATING_UP: l10n.t(
        'After burner gas consumption heating up'
      ),
      AFTER_BURNER_GAS_CONSUMPTION_STANDBY: l10n.t(
        'After burner gas consumption standby'
      ),
      AFTER_BURNER_GAS_CONSUMPTION_ROASTING: l10n.t(
        'After burner gas consumption roasting'
      ),
      AFTER_BURNER_GAS_CONSUMPTION_TOTAL: l10n.t(
        'After burner gas consumption total'
      ),
      AFTER_BURNER_OIL_CONSUMPTION_HEATING_UP: l10n.t(
        'After burner oil consumption heating up'
      ),
      AFTER_BURNER_OIL_CONSUMPTION_STANDBY: l10n.t(
        'After burner oil consumption standby'
      ),
      AFTER_BURNER_OIL_CONSUMPTION_ROASTING: l10n.t(
        'After burner oil consumption roasting'
      ),
      AFTER_BURNER_OIL_CONSUMPTION_TOTAL: l10n.t(
        'After burner oil consumption total'
      ),
      AFTER_BURNER_HYDROGEN_CONSUMPTION_HEATING_UP: l10n.t(
        'After burner hydrogen consumption heating up'
      ),
      AFTER_BURNER_HYDROGEN_CONSUMPTION_STANDBY: l10n.t(
        'After burner hydrogen consumption standby'
      ),
      AFTER_BURNER_HYDROGEN_CONSUMPTION_ROASTING: l10n.t(
        'After burner hydrogen consumption roasting'
      ),
      AFTER_BURNER_HYDROGEN_CONSUMPTION_TOTAL: l10n.t(
        'After burner hydrogen consumption total'
      ),
      POWER_CONSUMPTION_HEATING_UP: l10n.t('Power consumption heating up'),
      POWER_CONSUMPTION_STANDBY: l10n.t('Power consumption standby'),
      POWER_CONSUMPTION_ROASTING: l10n.t('Power consumption roasting'),
      POWER_CONSUMPTION_TOTAL: l10n.t('Power consumption total'),
      AIR_CONSUMPTION_HEATING_UP: l10n.t('Air consumption heating up'),
      AIR_CONSUMPTION_STANDBY: l10n.t('Air consumption standby'),
      AIR_CONSUMPTION_ROASTING: l10n.t('Air consumption roasting'),
      AIR_CONSUMPTION_TOTAL: l10n.t('Air consumption total'),
      PROFORTE_GAS_CONSUMPTION_HEATING_UP: l10n.t(
        'Proforte gas consumption heating up'
      ),
      PROFORTE_GAS_CONSUMPTION_STANDBY: l10n.t(
        'Proforte gas consumption standby'
      ),
      PROFORTE_GAS_CONSUMPTION_ROASTING: l10n.t(
        'Proforte gas consumption roasting'
      ),
      PROFORTE_GAS_CONSUMPTION_TOTAL: l10n.t('Proforte gas consumption total'),
      PROFORTE_POWER_CONSUMPTION_HEATING_UP: l10n.t(
        'Proforte power consumption heating up'
      ),
      PROFORTE_POWER_CONSUMPTION_STANDBY: l10n.t(
        'Proforte power consumption standby'
      ),
      PROFORTE_POWER_CONSUMPTION_ROASTING: l10n.t(
        'Proforte power consumption roasting'
      ),
      PROFORTE_POWER_CONSUMPTION_TOTAL: l10n.t(
        'Proforte power consumption total'
      ),
      PROFORTE_AIR_CONSUMPTION_HEATING_UP: l10n.t(
        'Proforte air consumption heating up'
      ),
      PROFORTE_AIR_CONSUMPTION_STANDBY: l10n.t(
        'Proforte air consumption standby'
      ),
      PROFORTE_AIR_CONSUMPTION_ROASTING: l10n.t(
        'Proforte air consumption roasting'
      ),
      PROFORTE_AIR_CONSUMPTION_TOTAL: l10n.t('Proforte air consumption total'),
      BURNER_OFF_DURING_ROAST: l10n.t('Burner off during roast'),
      PROCESS_WATER_ACTIVATED_AT_BURNER_OFF: l10n.t(
        'Process water activated at burner off'
      ),

      EMERGENCY_WATER: l10n.t('Emergency water'),

      END_ROASTER_MANUAL: l10n.t('Roast ended manually'),
      EMERGENCY_WATER_COOLER: l10n.t('Emergency water in cooler'),
      BAD_BATCH_OUTLET_SELECTED: l10n.t('Bad batch outlet selected'),
      DESTONER_BYPASS: l10n.t('Destoner bypassed'),
      ROAST_DURATION: l10n.t('Roast duration'),
      CYCLE_DURATION: l10n.t('Cycle duration'),
      COOLING_DURATION: l10n.t('Cooling duration'),
      START_TO_FIRST_CRACK_DURATION: l10n.t('First crack'),
      START_TO_COLOR_CHANCE_DURATION: l10n.t('Color change'),
      END_RELATIVE_UNDERPRESSURE_ROASTER: l10n.t('End rel. underpressure'),
      FEEDING_TEMPERATURE_ROASTER: l10n.t('Feeding temp.'),
      END_TEMPERATURE_ROAST: l10n.t('End temp.'),
      FEEDING_SUPPLY_AIR_TEMPERATURE_ROASTER: l10n.t(
        'Feeding supply air temp.'
      ),

      END_SUPPLY_AIR_TEMPERATURE_ROAST: l10n.t('End supply air temp.'),
      QUENCHING_DURATION: l10n.t('Quenching duration'),
      GAS_CONSUMPTION_MAIN_BURNER: l10n.t('Gas usage (main burner)'),
      GAS_CONSUMPTION_AFTER_BURNER: l10n.t('Gas usage (after burner)'),
      AIR_CONSUMPTION: l10n.t('Air usage'),
      DISCHARGING_DURATION_COOLER: l10n.t('Cooler discharging duration'),
      END_TEMPERATURE_COOLER: l10n.t('Cooler end temp.'),
      POWER_CONSUMPTION: l10n.t('Electricity usage'),
      WATER_CONSUMPTION_PRECOOLING: l10n.t('Water usage'),
      DISCHARGING_DURATION_ROASTER: l10n.t('Discharging duration'),
      PRODUCT_TEMPERATURE_PREHEATER_DISCHARGE: l10n.t(
        'Preheater discharge product temp.'
      ),

      INPUT_WEIGHT: l10n.t('Input weight'),
      OUTPUT_WEIGHT: l10n.t('Output weight'),
      IS_FIRST_BATCH: l10n.t('First batch'),
      IS_LAST_BATCH: l10n.t('Last batch'),
      NUMBER_OF_REDUCTION_STEPS: l10n.t('# of reduction steps'),

      PRODUCT_TEMPERATURE: l10n.t('Product temp.'),
      SUPPLY_AIR_TEMPERATURE: l10n.t('Supply air temp.'),

      ROAST_COLOR: l10n.t('Roast color'),
      ROAST_COLOR_2: l10n.t('Roast color 2'),
      ROAST_MOISTURE: l10n.t('Roasted moisture'),
      ROAST_DENSITY: l10n.t('Roasted density'),
      SENSORIAL_SCORE: l10n.t('Sensorial score'),
      WEIGHT_LOSS_ACTUAL: l10n.t('Weight loss'),
      TURNING_POINT_TIME: l10n.t('Turning point'),
      TURNING_POINT_TEMPERATURE: l10n.t('Turning point temp.'),
      RECIPE_ROAST_DURATION: l10n.t('Recipe roast duration'),
      RECIPE_COOLING_DURATION: l10n.t('Recipe cooling duration'),
      RECIPE_FEEDING_TEMPERATURE_ROASTER: l10n.t('Recipe feeding temp.'),
      RECIPE_END_TEMPERATURE_ROAST: l10n.t('Recipe end temp.'),
      RECIPE_FEEDING_SUPPLY_AIR_TEMPERATURE_ROASTER: l10n.t(
        'Recipe feeding supply air temp'
      ),
      RECIPE_END_SUPPLY_AIR_TEMPERATURE_ROAST: l10n.t(
        'Recipe end supply air temp.'
      ),
      RECIPE_INPUT_WEIGHT: l10n.t('Recipe input weight'),
    };

    this.labels = labels;
  }
}
