import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { cached, tracked } from '@glimmer/tracking';
import LabSampleStore from 'fabscale-app/services/store/lab-sample';
import { PaginatedLabSamples } from 'fabscale-app/models/lab-sample';
import RouterService from '@ember/routing/router-service';
import { deserializeDateRange } from 'fabscale-app/utilities/utils/serialize-date-range';
import { deserializeArray } from 'fabscale-app/utilities/utils/serialize-array';
import {
  RoastpicSortOption,
  SortDirection,
} from 'fabscale-app/models/enums/sort-options';
import { LabSample } from 'fabscale-app/models/lab-sample';

const DEFAULT_SORT_BY = 'ID';
const DEFAULT_SORT_DIRECTION = 'ASC';

interface Args {
  page?: number;
  pageSize?: number;
  sortBy?: RoastpicSortOption;
  sortDirection?: SortDirection;
  filters?: any;
  updatePage: (page?: number) => void;
  updatePageSize: (pageSize?: number) => void;
  updateSortBy: (sortBy?: RoastpicSortOption) => void;
  updateSortDirection: (sortDirection?: SortDirection) => void;
}

export default class PageRoastPicTable extends Component<Args> {
  @service('store/lab-sample') labSamplesStore: LabSampleStore;
  @service l10n: L10nService;
  @service router: RouterService;

  @tracked selectedItems = new Set();
  @tracked data: PaginatedLabSamples = {
    items: [],
    pageInfo: { page: 0, pageSize: 0, totalPages: 0, totalItemCount: 0 },
  };

  @tracked isLoading = true;
  @tracked isError = false;
  @tracked currentURL = '';

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.currentURL = this.router.currentURL;
    this.router.on('routeDidChange', this.updateCurrentURL);
  }

  willDestroy() {
    super.willDestroy();
    this.router.off('routeDidChange', this.updateCurrentURL);
  }

  @action
  updateCurrentURL() {
    this.currentURL = this.router.currentURL;
  }

  get queryParams() {
    let currentURL = this.currentURL;
    let queryString = currentURL?.split('?')[1] || '';
    let params = new URLSearchParams(queryString);
    let queryParams: { [key: string]: string } = {};
    for (let [key, value] of params.entries()) {
      queryParams[key] = value;
    }
    return queryParams;
  }

  get filters(): any {
    const { dateRange, coffeeTypes } = this.queryParams;

    const parsedDateRange = dateRange
      ? deserializeDateRange(dateRange as string)
      : undefined;
    const parsedCoffeeTypes = coffeeTypes
      ? deserializeArray(coffeeTypes as string)
      : undefined;

    return {
      dateRange: parsedDateRange,
      coffeeTypes: parsedCoffeeTypes,
    };
  }

  @cached
  get filtersKey() {
    return JSON.stringify(this.filters);
  }

  @tracked editId?: string;

  get page() {
    return this.args.page || 1;
  }

  get pageSize() {
    return this.args.pageSize || 25;
  }

  get sortBy() {
    return this.args.sortBy || DEFAULT_SORT_BY;
  }

  get sortDirection() {
    return this.args.sortDirection || DEFAULT_SORT_DIRECTION;
  }

  @action
  updateSort(sortBy: RoastpicSortOption, sortDirection: SortDirection) {
    if (sortBy !== this.args.sortBy) {
      this.args.updateSortBy(sortBy);
    }

    if (sortDirection !== this.args.sortDirection) {
      this.args.updateSortDirection(sortDirection);
    }
  }

  @action
  navigateBasedOnSelection() {
    let selectedIds = Array.from(this.selectedItems);

    if (selectedIds.length === 1) {
      this.router.transitionTo('routes/roast-pic.show', Number(selectedIds[0]));
    } else if (selectedIds.length === 2) {
      this.router.transitionTo(
        'routes/roast-pic.compare',
        Number(selectedIds[0]),
        Number(selectedIds[1])
      );
    }
  }

  @action
  toggleEditRow(labSample: LabSample | undefined) {
    this.editId = labSample?.id;
  }

  @action
  onRecordToggled(selectedItems: any) {
    this.selectedItems = selectedItems;
  }
}
