import { EnumsBase } from './-base';

export const UnitsOrdinal = [
  'COUNT',
  'BATCH_COUNTER',
  'STATE',
  'NONE',
] as const;
export type UnitOrdinal = typeof UnitsOrdinal[number];

export const UnitsWeight = ['KG', 'LB', 'GRAM', 'TON'] as const;
export type UnitWeight = typeof UnitsWeight[number];

export const UnitsTime = ['SECOND', 'MILLISECOND', 'MINUTE', 'HOUR'] as const;
export type UnitTime = typeof UnitsTime[number];

export const UnitsTemperature = ['CELSIUS', 'FAHRENHEIT'] as const;
export type UnitTemperature = typeof UnitsTemperature[number];

export const UnitsSpeed = [
  'METER_PER_SECONDS',
  'KILOMETER_PER_HOUR',
  'MILLIMETER_PER_MINUTE',
  'MILLIMETER_PER_SECOND',
] as const;
export type UnitSpeed = typeof UnitsSpeed[number];

export const UnitsFrequency = ['RPM', 'HERTZ'] as const;
export type UnitFrequency = typeof UnitsFrequency[number];

export const UnitsVolume = [
  'LITER',
  'CUBIC_METER',
  'HECTOLITER',
  'GALLON_US',
  'GALLON_UK',
] as const;
export type UnitVolume = typeof UnitsVolume[number];

export const UnitsVolumeFlowrate = [
  'LITER_PER_MINUTE',
  'CUBIC_METER_PER_HOUR',
  'LITER_PER_HOUR',
  'CUBIC_NORMMETER_PER_HOUR',
  'CUBIC_FEET_PER_HOUR',
  'CUBIC_FEET_PER_MINUTE',
  'GALLON_PER_HOUR',
  'CUBIC_STANDARD_FEET_PER_HOUR',
] as const;
export type UnitVolumeFlowrate = typeof UnitsVolumeFlowrate[number];

export const UnitsPressure = [
  'DECIPASCAL',
  'MILLIBAR',
  'DECAPASCAL',
  'INCH_WATER_COLUMN',
  'METER_WATER_COLUMN',
  'BAR',
] as const;
export type UnitPressure = typeof UnitsPressure[number];

export const UnitsRatio = ['PERCENTAGE', 'PARTS_PER_MILLION'] as const;
export type UnitRatio = typeof UnitsRatio[number];

export const UnitsEnergy = [
  'KWH',
  'WATT_HOUR',
  'JOULE',
  'NEWTONMETER',
] as const;
export type UnitEnergy = typeof UnitsEnergy[number];

export const UnitsMassFlowrate = [
  'KG_PER_HOUR',
  'LB_PER_HOUR',
  'GRAM_PER_HOUR',
  'KG_PER_SECOND',
  'TONS_PER_HOUR',
  'LB_PER_SECOND',
  'OZ_PER_SECOND',
] as const;
export type UnitMassFlowrate = typeof UnitsMassFlowrate[number];

export const UnitsDensity = [
  'GRAM_PER_LITER',
  'GRAM_PER_MILLILITER',
  'GRAM_PER_CUBIC_DECIMETER',
] as const;
export type UnitDensity = typeof UnitsDensity[number];

export const UnitsRoastColor = [
  'PROBAT_COLORETTE',
  'KONICA_MINOLTA',
  'AGTRON',
  'NEUHAUS_NEOTEC',
  'HUNTER_LAB',
] as const;
export type UnitRoastColor = typeof UnitsRoastColor[number];

export type Unit =
  | UnitOrdinal
  | UnitWeight
  | UnitTime
  | UnitTemperature
  | UnitSpeed
  | UnitFrequency
  | UnitVolume
  | UnitVolumeFlowrate
  | UnitPressure
  | UnitRatio
  | UnitEnergy
  | UnitMassFlowrate
  | UnitDensity
  | UnitRoastColor;

export type UnitType =
  | typeof UnitsOrdinal
  | typeof UnitsWeight
  | typeof UnitsTime
  | typeof UnitsTemperature
  | typeof UnitsSpeed
  | typeof UnitsFrequency
  | typeof UnitsVolume
  | typeof UnitsVolumeFlowrate
  | typeof UnitsPressure
  | typeof UnitsRatio
  | typeof UnitsEnergy
  | typeof UnitsMassFlowrate
  | typeof UnitsDensity
  | typeof UnitsRoastColor;

export class UnitsEnums extends EnumsBase<Unit> {
  constructor(...rest: any) {
    super(...rest);
    this.labels = {
      COUNT: '',
      MILLISECOND: 'ms',
      SECOND: 's',
      MINUTE: 'm',
      HOUR: 'h',
      GRAM: 'g',
      KG: 'kg',
      LB: 'lb',
      CELSIUS: '°C',
      FAHRENHEIT: '°F',
      METER_PER_SECONDS: 'm/s',
      KILOMETER_PER_HOUR: 'km/h',
      LITER: 'l',
      HECTOLITER: 'hl',
      CUBIC_METER: 'm³',
      GALLON_US: 'gal',
      GALLON_UK: 'gal',
      CUBIC_METER_PER_HOUR: 'm³/h',
      LITER_PER_HOUR: 'l/h',
      DECIPASCAL: 'dPa',
      MILLIBAR: 'mbar',
      PERCENTAGE: '%',
      PARTS_PER_MILLION: 'ppm',
      KWH: 'kWh',
      KG_PER_HOUR: 'kg/h',
      GRAM_PER_HOUR: 'g/h',
      LB_PER_HOUR: 'lb/h',
      GRAM_PER_LITER: 'g/l',
      GRAM_PER_MILLILITER: 'g/ml',
      PROBAT_COLORETTE: 'Colorette',
      KONICA_MINOLTA: 'Minolta',
      AGTRON: 'Agtron',
      NEUHAUS_NEOTEC: 'Neotec',
      HUNTER_LAB: 'Hunter Lab',
      NONE: '',
      STATE: '',
      TON: 't',
      MILLIMETER_PER_MINUTE: 'mm/min',
      MILLIMETER_PER_SECOND: 'mm/s',
      RPM: 'rpm',
      HERTZ: 'Hz',
      METER_PER_SECOND_SQUARED: 'm/s²',
      MILLIMETER_PER_SECOND_SQUARED: 'mm/s²',
      FEET_PER_SECOND_SQUARED: 'ft/s²',
      INCH_PER_SECOND_SQUARED: 'in/s²',
      CUBIC_NORMMETER_PER_HOUR: 'Nm³/h',
      CUBIC_FEET_PER_HOUR: 'ft³/h',
      CUBIC_FEET_PER_MINUTE: 'ft³/min',
      GALLON_PER_HOUR: 'gal/h',
      CUBIC_STANDARD_FEET_PER_HOUR: 'scf/h',
      DECAPASCAL: 'daPa',
      INCH_WATER_COLUMN: 'inH₂O',
      METER_WATER_COLUMN: 'mH₂O',
      BAR: 'bar',
      WATT_HOUR: 'Wh',
      KG_PER_SECOND: 'kg/s',
      TONS_PER_HOUR: 't/h',
      LB_PER_SECOND: 'lb/s',
      OZ_PER_SECOND: 'oz/s',
      GRAM_PER_CUBIC_DECIMETER: 'g/dm³',
      AMPERE: 'A',
      MILLIAMPERE: 'mA',
      KILOWATT: 'kW',
      WATT: 'W',
      MILLIMETER: 'mm',
      CENTIMETER: 'cm',
      DECIMETER: 'dm',
      MICROMETER: 'µm',
      METER: 'm',
      INCH: 'in',
      DEGREE: '°',
      RADIAL: 'rad',
      JOULE: 'J',
      NEWTONMETER: 'Nm',
      NEWTON: 'N',
    };
  }

  getLabel(unit: string) {
    return this.labels[unit] || '';
  }
}
