import { service } from '@ember/service';
import { restartableTask } from 'ember-concurrency';
import LabSampleStore from 'fabscale-app/services/store/lab-sample';
import SharedDropdownBase from '../../base/dropdown-base';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class SharedDropdownLotNumber extends SharedDropdownBase<string> {
  @service('store/lab-sample') labSamplesStore: LabSampleStore;

  @tracked _distinctLotNumber: string[] = [];
  @tracked distinctLotNumber: string[] = [];
  @tracked _searchTerm?: string;
  @tracked _noResultsFound = false;
  @tracked isLoading = true;

  private dropdownAPI: any;

  constructor(owner: unknown, args: any) {
    super(owner, args);

    this.loadLotNumbers.perform();
  }

  private loadLotNumbers = restartableTask(async () => {
    this._distinctLotNumber = this.distinctLotNumber =
      await this.labSamplesStore.getDistinctLotNumbers();
    this.isLoading = false;
  });

  override get options() {
    return this.distinctLotNumber;
  }

  get searchTerm() {
    return this._searchTerm;
  }

  get noResultsFound() {
    return this._noResultsFound;
  }

  @action
  onKeydown(_dropdown: any, e: any) {
    // backspace used for last remaining character and power-select does not update the UI, so we have to manually adjust it;
    if (e.charCode === 0 && _dropdown.searchText.length === 1) {
      this._searchTerm = '';
      this.distinctLotNumber = this._distinctLotNumber;
      this._noResultsFound = false;
    }
  }

  @action
  onSearch(_dropdown: any, ev: any) {
    this._searchTerm = ev.searchText;

    this.distinctLotNumber = this._distinctLotNumber.filter(
      (lotNumber: string) => {
        const lotNumberToLower = lotNumber.toLowerCase();
        const searchTermLower = this._searchTerm!.toLowerCase();

        return lotNumberToLower.includes(searchTermLower);
      }
    );

    this._noResultsFound = this.distinctLotNumber.length === 0;
  }

  @action
  onCreate(option: string): void {
    this._noResultsFound = false;
    this._searchTerm = '';
    this._distinctLotNumber.push(option);
    this.distinctLotNumber = this._distinctLotNumber;
    this.args.onCreate(option);
    this.dropdownAPI.actions.close();
  }

  @action
  onRegisterPowerSelect(dropdownAPI: any) {
    this.dropdownAPI = dropdownAPI;
  }
}
