import Application from '@ember/application';
import Resolver from './resolver';
import loadInitializers from 'ember-load-initializers';
import config from 'fabscale-app/config/environment';
import { setupSentry } from './sentry';
import 'ember-cached-decorator-polyfill';
import { extendResolver } from 'ember-can';
import { registerDeprecationHandler } from '@ember/debug';

setupSentry();

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = extendResolver(Resolver);
  tmpDeprecationHandler = registerDeprecationHandler(() => {
    return;
  });
}

loadInitializers(App, config.modulePrefix);
