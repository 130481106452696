/* import __COLOCATED_TEMPLATE__ from './size.hbs'; */
import Component from '@glimmer/component';
import { LabSample } from 'fabscale-app/models/lab-sample';
import { DateTime } from 'luxon';
import { deserializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import { Chart, ChartItem } from 'chart.js/auto';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { service } from '@ember/service';
import PdfService from 'fabscale-app/services/pdf';
import { tracked } from '@glimmer/tracking';
import ScreenService from 'fabscale-app/services/screen';

interface Args {
  firstLabSample: LabSample;
  secondLabSample: LabSample;
  labSampleUpdate: (labSample: LabSample) => void;
}

export default class RoastPicCompareSize extends Component<Args> {
  @service pdf: PdfService;
  @service screen: ScreenService;
  @tracked currentOffset = 0;
  @tracked displayedLengthValue = '';
  @tracked displayedWidthValue = '';
  @tracked displayedScreenSizeValue = '';

  get isMobile() {
    return this.screen.isMobile;
  }

  lengthChart: Chart | null = null;
  widthChart: Chart | null = null;
  screenSizeChart: Chart | null = null;

  printPageTask = dropTask(async () => {
    let fileName = `fabscale-roastpic-size-${DateTime.local().toISODate()}.pdf`;

    await this.pdf.generateForCurrentPageTask.perform(fileName);
  });

  @action
  moveTo(offset: number) {
    this.currentOffset = offset;
  }

  @action
  drawChart(
    element: HTMLCanvasElement,
    firstSampleData: any[] | undefined,
    secondSampleData: any[] | undefined,
    dataKey: string,
    valueKey: string
  ) {
    const ctx = element.getContext('2d') as ChartItem;
    let labels: any[];
    let datasets: any[];

    labels = Array.from(
      new Set([
        ...(firstSampleData?.map((bin) => bin.count) || []),
        ...(secondSampleData?.map((bin) => bin.count) || []),
      ])
    );

    datasets = [
      {
        label: `#${this.args.firstLabSample.id}`,
        data: firstSampleData,
        backgroundColor: '#0F7799',
      },
      {
        label: `#${this.args.secondLabSample.id}`,
        data: secondSampleData,
        backgroundColor: '#A0D2F7',
      },
    ];

    const chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        parsing: {
          xAxisKey: 'count',
          yAxisKey: 'value',
        },
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            onClick: (e, legendItem, legend) => {
              Chart.defaults.plugins.legend.onClick.call(
                legend,
                e,
                legendItem,
                legend
              );

              this.updateDisplayedValue(chart, valueKey);
            },
          },
          title: {
            display: true,
          },
        },
      },
    });

    if (dataKey === 'lengthHist') {
      this.lengthChart = chart;
    } else if (dataKey === 'widthHist') {
      this.widthChart = chart;
    } else if (dataKey === 'screenSizeHist') {
      this.screenSizeChart = chart;
    }

    this.updateDisplayedValue(chart, valueKey);
  }

  updateDisplayedValue(chart: Chart, valueKey: string) {
    const visibleDatasets = chart.data.datasets.filter((dataset, index) => {
      const meta = chart.getDatasetMeta(index);
      return meta && !meta.hidden;
    });

    let displayedValue = '';

    visibleDatasets.forEach((dataset, idx) => {
      let sample: any = '';
      if (dataset.label === `#${this.args.firstLabSample.id}`) {
        sample = this.args.firstLabSample;
      } else if (dataset.label === `#${this.args.secondLabSample.id}`) {
        sample = this.args.secondLabSample;
      }

      if (sample) {
        const value = sample[valueKey];
        const deviationKey = valueKey.replace('Avg', 'Deviation');
        const deviation = sample[deviationKey];

        if (valueKey.endsWith('Avg')) {
          displayedValue += `${value} ± ${deviation}mm`;
        } else if (valueKey === 'screenSizeMode') {
          displayedValue += `${value}`;
        }

        if (idx < visibleDatasets.length - 1) {
          displayedValue += ' / ';
        }
      }
    });

    if (valueKey === 'lengthAvg') {
      this.displayedLengthValue = displayedValue || '-';
    } else if (valueKey === 'widthAvg') {
      this.displayedWidthValue = displayedValue || '-';
    } else if (valueKey === 'screenSizeMode') {
      this.displayedScreenSizeValue = displayedValue || '-';
    }
  }

  @action
  drawScreenSizeChart(element: HTMLCanvasElement) {
    this.drawChart(
      element,
      this.args.firstLabSample.screenSizeHist,
      this.args.secondLabSample.screenSizeHist,
      'screenSizeHist',
      'screenSizeMode'
    );
  }

  @action
  drawWidthChart(element: HTMLCanvasElement) {
    this.drawChart(
      element,
      this.args.firstLabSample.widthHist,
      this.args.secondLabSample.widthHist,
      'widthHist',
      'widthAvg'
    );
  }

  @action
  drawLengthChart(element: HTMLCanvasElement) {
    this.drawChart(
      element,
      this.args.firstLabSample.lengthHist,
      this.args.secondLabSample.lengthHist,
      'lengthHist',
      'lengthAvg'
    );
  }

  @action
  onLabSampleUpdate(labSample: LabSample) {
    this.args.labSampleUpdate(labSample);
  }

  formatCreatedDate(sample: LabSample) {
    const date = deserializeDate(sample.createdDate as any);
    return `Recorded at ${date.toLocaleString(DateTime.DATETIME_SHORT)}`;
  }

  get formattedFirstCreatedDate() {
    return this.formatCreatedDate(this.args.firstLabSample);
  }

  get formattedSecondCreatedDate() {
    return this.formatCreatedDate(this.args.secondLabSample);
  }
}
