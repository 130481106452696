import { service } from '@ember/service';
import { restartableTask } from 'ember-concurrency';
import SharedDropdownBase from '../../base/dropdown-base';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import StoreRoastBatchService from 'fabscale-app/services/store/roast-batch';
import { PageDef, SortDef } from 'fabscale-app';
import { serializeOptionalDate } from 'fabscale-app/utilities/utils/serialize-date';

export default class SharedDropdownBatchTimestamp extends SharedDropdownBase<{
  id: string;
  startDate: string;
}> {
  @service('store/roast-batch') roastBatchStore: StoreRoastBatchService;

  @tracked _distinctLotNumber: string[] = [];
  @tracked distinctLotNumber: string[] = [];

  @tracked _roastBatches: { id: string; startDate: string }[] = [];
  @tracked roastBatches: { id: string; startDate: string }[] = [];

  @tracked _searchTerm?: string;
  @tracked _noResultsFound = false;
  @tracked isLoading = true;

  constructor(owner: unknown, args: any) {
    super(owner, args);

    this.loadRoastBatches.perform();
  }

  private loadRoastBatches = restartableTask(async () => {
    const filters = {
      dateFrom: serializeOptionalDate(this.args.dateRange?.start),
      dateTo: serializeOptionalDate(this.args.dateRange?.end),
      plantAssetIds: [],
    };

    const pageDef: PageDef = {
      page: 1,
      pageSize: 25,
    };

    const sortDef: SortDef = {
      sortBy: 'SOURCE_SYSTEM_RECORDING_DATE',
      sortDirection: 'DESC',
    };

    const response = await this.roastBatchStore.queryPaginated(
      filters,
      pageDef,
      sortDef
    );

    this._roastBatches = this.roastBatches = response.roastBatches.map(
      (roastBatch: any) => {
        return {
          id: roastBatch.id,
          startDate: roastBatch.startDate,
        };
      }
    );

    this.isLoading = false;
  });

  override get options() {
    return this.roastBatches;
  }

  get searchTerm() {
    return this._searchTerm;
  }

  get noResultsFound() {
    return this._noResultsFound;
  }

  @action
  onKeydown(_dropdown: any, e: any) {
    // backspace used for last remaining character and power-select does not update the UI, so we have to manually adjust it;
    if (e.charCode === 0 && _dropdown.searchText.length === 1) {
      this._searchTerm = '';
      this.distinctLotNumber = this._distinctLotNumber;
      this._noResultsFound = false;
    }
  }

  @action
  onSearch(_dropdown: any, ev: any) {
    this._searchTerm = ev.searchText;

    this.distinctLotNumber = this._distinctLotNumber.filter(
      (lotNumber: string) => {
        const lotNumberToLower = lotNumber.toLowerCase();
        const searchTermLower = this._searchTerm!.toLowerCase();

        return lotNumberToLower.includes(searchTermLower);
      }
    );

    this._noResultsFound = this.distinctLotNumber.length === 0;
  }
}
